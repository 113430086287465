import React, {useState, useEffect, useRef, memo, useMemo, useCallback} from "react";
import {Table} from "antd";
import {useDispatch, useSelector} from "react-redux";
import {useLocation} from "react-router-dom";
import {setSelected, setFiltered} from "../../../redux/reducers/mainTable";
import useWindowSize from "../../../hooks/useWindowSize";
import {useVT} from "virtualizedtableforantd4";
import ViewSelector from "../../Timeline/ViewSelector/ViewSelector";
import './TableWithGroups.scss';
import {TimeLineTab, ViewMode} from "../../../constants";
import ScheduleView from "../ScheduleView/ScheduleView";

const getRowIdDefault = (record) => record ? `${record.Id}_type_${record.ItemType || 0}` : undefined;

const ActionsCell = memo(({record, actions, hide}) => {
    if (hide) return null;
    return (<div style={{minWidth: `${actions.length * 24 + 2}px`}}>
        {actions.map((ActionComponent, i) => (<ActionComponent key={i} record={record}/>))}
    </div>);
});

const TableWithGroups = ({
                             items = [],
                             columns = [],
                             FilterBar,
                             GroupTable,
                             loading,
                             rowClassName,
                             loadItemToEdit,
                             groupFooter,
                             filterBarProps = {},
                             activeKey,
                             renderCell,
                             actions = [],
                             footer,
                             enableInfinityScrolling = false,
                             preserveFilteredData = false,
                             hideRowSelection = false,
                             actionsColumnWidth,
                             sortOrder,
                             onChange,
                             onGroupByChange,
                             onOpenCloneEventModal,
                         }) => {
    const dispatch = useDispatch();
    const size = useWindowSize();
    const [filteredData, setFilteredData] = useState(items);
    const [groupBy, setGroupBy] = useState();
    const location = useLocation();
    const tableRef = useRef();
    const tableHeight = useMemo(() => (size?.height || 800) - (tableRef.current?.offsetTop || 0) - 40, [size]);
    const [vt] = useVT(() => ({scroll: {y: tableHeight}}), [tableHeight, items]);
    const itemToEdit = useSelector((state) => state.detailsPanel.item);
    const selected = useSelector((state) => state.mainTable.selected);
    const currentView = useSelector((state) => state.timeline.currentView);

    useEffect(() => {
        dispatch(setSelected([]));
        if (preserveFilteredData) {
            dispatch(setFiltered([]));
        }
    }, [location, dispatch, preserveFilteredData]);

    useEffect(() => {
        if (onGroupByChange) {
            onGroupByChange(groupBy);
        }
    }, [groupBy, onGroupByChange]);

    const handleChange = useCallback((pagination, filters, sorter, extra) => {
        if (onChange) {
            onChange(pagination, filters, sorter, extra);
        }
    }, [onChange]);

    const onRowSelectionChange = useCallback((selection) => {
        const selectedIds = (selected || []).map(getRowIdDefault);
        const filteredDataIds = filteredData.map(getRowIdDefault);
        const prevSelectedItems = selectedIds.filter((i) => !filteredDataIds.includes(i));
        const selectedItems = filteredData.filter((i) => selection.includes(getRowIdDefault(i)) || prevSelectedItems.includes(getRowIdDefault(i)));
        dispatch(setSelected(selectedItems));
    }, [selected, filteredData, dispatch]);

    const selectedRowKeys = useMemo(() => (selected || []).map(getRowIdDefault), [selected]);

    const applyFilters = useCallback((filtersData) => {
        setFilteredData(filtersData);
        if (preserveFilteredData) {
            dispatch(setFiltered(filtersData));
        }
    }, [dispatch, preserveFilteredData]);

    const calculatedActionsColumnWidth = actionsColumnWidth ?? (actions.length > 0 ? `${actions.length * 24 + 2}px` : "100px");

    const actionsColumn = {
        key: "action",
        className: "actions-column",
        width: calculatedActionsColumnWidth,
        alwaysVisible: true,
        render: (record) => <ActionsCell record={record} actions={actions} hide={selected.length > 1}/>,
    };

    const defaultRowClassName = (record) => (record && itemToEdit && record.Id === itemToEdit.Id ? "row-selected-for-edit" : "");

    const renderTable = (isGroupBy) => {
        const commonProps = {
            loading,
            columns: [...columns, actionsColumn],
            dataSource: filteredData,
            rowKey: getRowIdDefault,
            rowClassName: rowClassName || defaultRowClassName,
            onRow: (record) => ({onClick: () => loadItemToEdit(record)}),
        };

        if (isGroupBy) {
            return <GroupTable {...commonProps} groupByFilter={groupBy}
                               enableInfinityScrolling={enableInfinityScrolling} rowSelection={{type: "checkbox"}}
                               footer={groupFooter}/>;
        }

        return (<Table
            ref={tableRef}
            scroll={{y: tableHeight}}
            sortOrder={sortOrder}
            pagination={false}
            className="common-table"
            size="small"
            components={vt}
            rowSelection={hideRowSelection ? null : {
                type: "checkbox", selectedRowKeys, renderCell, onChange: onRowSelectionChange,
            }}
            onChange={handleChange}
            summary={filteredData.length > 1 ? footer : null}
            {...commonProps}
        />);
    };

    const renderContent = () => {
        if (activeKey === TimeLineTab.Events) {
            if (currentView === ViewMode.List) {
                return renderTable(groupBy);
            }
            if ([ViewMode.Month, ViewMode.Day, ViewMode.Week].includes(currentView)) {
                return <ScheduleView items={filteredData} loadItemToEdit={loadItemToEdit}
                                     onOpenCloneEventModal={onOpenCloneEventModal}/>;
            }
        }
        return renderTable(groupBy);
    };

    return (<div className="table-with-groups">
        <div className="header-row">
            <div className="filter-bar-container">
                <FilterBar items={items} applyFilters={applyFilters} applyGroupBy={setGroupBy}
                           viewMode={currentView} {...filterBarProps} />
            </div>
            <div className="view-selector-container">
                {activeKey === TimeLineTab.Events && <ViewSelector/>}
            </div>
        </div>
        {renderContent()}
    </div>);
};

export default memo(TableWithGroups);
